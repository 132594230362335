<template>
  <v-card>
    <v-card-text>
    <h3>Profile</h3>
    <v-form @submit.prevent ref="form">
      <v-text-field
        id="email"
        v-model="profile.email"
        label="Email"
        disabled
        readonly
      ></v-text-field>
     <!-- <v-text-field
        label="Discord"
        id="discord"
        :rules="[
          value =>
            !value ||
            !!value.match(/.+#\d{4}/i) ||
            'Invalid discord pattern, must be the full account name from your profile'
        ]"
        placeholder="discordname#0000"
        v-model.trim="profile.discord"
      ></v-text-field>

      <v-text-field
        id="github"
        label="Github Username"
        v-model.trim="profile.github"
      ></v-text-field>-->

      <small
        >(<a href="https://myaccount.google.com/profile/name">Edit via Google</a
        >)</small
      >
      <v-text-field
        id="dn"
        label="Display Name"
        v-model="profile.displayName"
        disabled
        readonly
      ></v-text-field>

      <small
        >(<a href="https://myaccount.google.com/profile/name">Edit via Google</a
        >)</small
      >
      <v-text-field
        id="nn"
        label="First Name"
        v-model="profile.firstname"
        disabled
        readonly
      ></v-text-field>

      <small
        >(<a href="https://myaccount.google.com/profile/name">Edit via Google</a
        >)</small
      >
      <v-text-field
        id="nn"
        label="Last Name"
        v-model="profile.lastname"
        disabled
        readonly
      ></v-text-field>
      <!--<v-textarea
          outlined
          label="Bio"
         v-model="profile.bio"
        ></v-textarea>-->
      <v-switch v-model="profile.dark" label="Dark Mode"></v-switch>
      <v-text-field outlined label="API Key" :disabled="true" :readonly="true" v-model="apiKey"/><v-btn color="primary" @click.stop="getAPIKey">Get API Key</v-btn>
      <v-divider class="my-2"></v-divider>
     <!-- <span v-if="profile.notifications">
        <div class="h3">Email Notification Settings</div>
        <v-switch
          v-model="profile.notifications.newAssignment"
          label="New Assignment"
        ></v-switch>
        <v-switch
          v-model="profile.notifications.updatedAssignment"
          label="Updated Assignment"
        ></v-switch>
        <v-switch
          v-model="profile.notifications.gradePosted"
          label="Grade Posted"
        ></v-switch>
        <v-switch
          v-model="profile.notifications.gradeUpdated"
          label="Grade Updated"
        ></v-switch>
        <v-switch
          v-model="profile.notifications.newComment"
          label="New Comment"
        ></v-switch>
      </span>-->
      <v-progress-linear
        v-if="isSaving"
        color="blue"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-btn type="submit" @click="save">Save</v-btn>
    </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import * as fb from "../firebase";
//import Vue from "vue";
/*const defaultNotifications = () => {
  return {
    newAssignment: false,
    updatedAssignment: false,
    newComment: false,
    gradePosted: false,
    gradeUpdated: false
  };
};*/
export default {
  beforeMount() {
    this.loadUser();
  },
  computed: {
    ...mapState(["userProfile"]),
    profileURL(){
      return `/profile/${this.userProfile.ucid}/edit`;
    }
  },
  watch: {
    userProfile(n, o) {
      if (n || o) {
        this.loadUser();
      }
    }
  },
  methods: {
    async loadUser() {
      this.profile.email = this.userProfile.email;
     // this.profile.discord = this.userProfile.discord;
    //  this.profile.github = this.userProfile.github;
      this.profile.displayName = this.userProfile.displayName;
      this.profile.firstname = this.userProfile.firstname;
      this.profile.lastname = this.userProfile.lastname;
      this.profile.bio = this.userProfile.bio;
      this.profile.dark = (this.userProfile.theme || "light") === "dark";
     // Vue.set(this.profile, "notifications", defaultNotifications());
      await this.userProfile.uid;
      /* TODO add settings
      if (this.userProfile.uid) {
        let settings = await fb.usersCollection
          .doc(this.userProfile.uid + "/settings/" + this.userProfile.uid)
          .get()
          .then(doc => {
            let d = doc.data();
            console.log("settings", d);
            return d;
          });
        if (settings) {
          this.profile.notifications = Object.assign(
            this.profile.notifications,
            settings.notifications || {}
          );
          //this.profile.notifications = settings.notifications;
        }
      }*/
    },
    async getAPIKey(){
      if(!this.apiKey){
        let getKey = fb.functions.httpsCallable("getAPIKey");
        await getKey().then(result=>{
          console.log(result);
          this.apiKey = result.data;
        });
      }
    },
    async save() {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      const self = this;
      console.log("profile", this.profile);
      let updateUserProfile = fb.functions.httpsCallable("updateUserProfile");
      await updateUserProfile({
        id_token: this.userProfile.idToken,
        token: this.userProfile.token,
       // discord: this.profile.discord,
       // github: this.profile.github,
       //bio: this.profile.bio,
        theme: this.profile.dark ? "dark" : "light",
        notifications: this.profile.notifications || {}
      })
        .then(result => {
          this.userProfile.theme = this.profile.dark ? "dark" : "light";
          this.userProfile.token = result.data.token;
          console.log("function result", result);
          this.isSaving = false;
          //this.flashMessage.success({ message: "Profile saved" });
          console.log("profile saved");
        })
        .catch(async error => {
          console.log("Function error", error);
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log(code, message, details);
          if (code === "unauthenticated") {
            await this.$store.dispatch("login", this).then(() => {
              if (!self.relogAttempt) {
                self.relogAttempt = true;
                self.isSaving = false;
                self.save();
              } else {
               /* self.flashMessage.error({
                  message:
                    "Error saving profile: " + error + ". Please try again"
                });*/
                console.log( "Error saving profile: " + error + ". Please try again");
              }
            });
          }

          this.isSaving = false;
        });
    }
  },
  data() {
    return {
      relogAttempt: false,
      apiKey:"",
      profile: {
        email: "",
        //discord: "",
        //github: "",
        displayName: "",
        firstname: "",
        lastname: "",
        bio:""
      },
      isSaving: false
    };
  }
};
</script>
