<template>
  <v-card class="pa-2" style="min-height: 70vh">
    <v-card-title>Profile</v-card-title>
    <v-card-text class="full-height" style="">
      <v-row>
        <v-col>
          <v-switch
            v-if="isMyProfile"
            v-model="isEditing"
            inset
            :label="isEditing ? 'Editing' : 'Viewing'"
          ></v-switch>
        </v-col>
        <v-col>
          <v-btn color="primary" v-if="isEditing" @click.stop="saveProfile"
            >Save</v-btn
          >
        </v-col>
      </v-row>
      <div v-if="isEditing">
        <div class="h3">Summary</div>
        <small>(Shown in lists or short excerpts)</small>
        <v-textarea v-model="summary" outlined></v-textarea>
        <div class="h3">Full Profile</div>
        <small>(Shown only when someone visits your profile page)</small>
        <v-text-field outlined
          v-if="slug"
          label="Custom URL"
          v-model="slug"
          :placeholder="slug"
        ></v-text-field>
        <tiny-editor
          v-if="isEditing"
          :init="tinyConfig"
          v-model="profile"
        ></tiny-editor>
        <div class="h3" v-if="isEditing">Preview</div>
      </div>
      <div v-html="profileContent"></div>
    </v-card-text>
  </v-card>
</template>
<script>
import { profileCollection, storage, STATE_CHANGED } from "../firebase";
import { mapState } from "vuex";

//http://www.4codev.com/javascript/get-all-images-src-from-the-html-string-with-regex-in-javascript-idpx3779185093643885165.html
const getImages = (string) => {
  const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
  const images = [];
  let img;
  while ((img = imgRex.exec(string))) {
    images.push(img[1]);
  }
  return images;
};
export default {
  props: {
    user: {
      type: String,
      required: true,
    },
    edit: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      profile: "hello",
      summary: "",
      original: "",
      pendingImages: [],
      slug: "",
      isEditing: false,
      author: false,
      tinyConfig: {
        height: 500,

        plugins: [
          "print preview paste importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help charmap quickbars",
        ],
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media template link anchor codesample | ltr rtl",
        toolbar_sticky: true,
        menubar: "file edit view insert format tools table help",
        quickbars_insert_toolbar: "h1 h2 h3 | codesample code samp | link",
        contextmenu: "link table paste",
        //	skin: false ? 'oxide-dark' : 'oxide',
        //	content_css: false ? 'dark' : 'default',
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quicktable",
        //importcss_append: true,
        paste_data_images: true,
        images_upload_handler: this.saveImage,
        imagetools_toolbar:
          "rotateleft rotateright | flipv fliph | editimage imageoptions",
        image_advtab: true,
        imagetools_cors_hosts: ["localhost", "firebasestorage.googleapis.com"],

        imagetools_fetch_image: this.fetchBlob,
        images_reuse_filename: true,
        relative_urls: false,
        remove_script_host: false,
      },
    };
  },
  methods: {
    async fetchBlob(img) {
      console.log("img", img);

      return await fetch(img.src, { responseType: "blob" }).then(
        async (resp) => {
          let blob = await resp.blob();
          console.log("resp", resp, blob);
          return blob;
        }
      );
    },
    async saveImage(blob, success, error) {
      console.log("blob", blob, blob.blob(), blob.blobUri(), blob.name());
      console.log("ucid", this.ucid);
      if (this.ucid) {
        let data = await fetch(blob.blobUri());
        data = await data.blob();
        if (!data.name) {
          data.name = blob.filename();
          console.log("(before)set name from file", data.name);
          if (data.name.includes("%2F")) {
            let n = data.name.split("%2F");
            data.name = n[n.length - 1];
          } else {
            data.name = Date.now() + "_" + data.name;
          }
          console.log("set name from file", data.name);
        }
        // let b64 = blob.base64();
        console.log("data", data);
        const storageRef = storage.ref();
        const upload = storageRef
          .child(`images/${this.ucid}/${data.name}`)
          .put(data);
        upload.on(
          STATE_CHANGED,
          (snap) => console.log("snap", snap),
          (err) => {
            console.log("upload error", err);
            error(err);
          },
          () => {
            console.log("image uploaded, fetching url");
            let url = upload.snapshot.ref.getDownloadURL().then((url) => {
              console.log("Upload url", url);
              this.pendingImages.push(url);
              success(url);
            });
            return url;
          }
        );
      }
    },
    mapData(data) {
      this.profile = data.content;
      this.original = data.content;
      this.slug = data.slug;
      this.summary = data.summary;
      this.author = data.uid;
      console.log("loaded", data);
    },
    async loadProfile() {
      if (this.user) {
        let profile = await profileCollection
          .doc(this.user)
          .get()
          .catch((err) => {
            console.log("doc fetch error", err);
          });
        if (profile.exists) {
          let data = profile.data();
          this.mapData(data);
        } else {
          profile = await profileCollection
            .where("slug", "==", this.user.toLowerCase())
            .limit(1)
            .get()
            .then((doc) => {
              return doc;
            });
          if (!profile.empty) {
            let data = profile.docs[0].data();
            this.mapData(data);
          } else {
            //may be ok if this is the first visit
            console.log("Profile not found for ", this.user);
            this.profile = "The profile you're looking for does not exist.";
          }
        }
      }
    },
    async saveProfile() {
      if (this.userProfile.ucid) {
        //TODO ensure slug isn't used already
        //may need secondary collection of slugs to do this in rules
        console.log("images", this.originalImages, this.newImages);
        let slug = !!this.slug === true ? this.slug : this.userProfile.ucid;
        slug = slug.toLowerCase();
        await profileCollection.doc(this.userProfile.ucid).set(
          {
            content: this.profileContent,
            slug: slug,
            updated: new Date(),
            uid: this.userProfile.uid,
            summary: this.summary,
          },
          { merge: true }
        );
        this.pendingImages = [];
        if (this.originalImages.length > 0) {
          for (let img of this.originalImages) {
            if (!this.newImages.includes(img)) {
              console.log("cleaning up deleted image", img);
              await storage
                .refFromURL(img)
                .delete()
                .then((task) => console.log("delete", task));
            }
          }
        }
        this.original = this.profileContent;
      }
    },
    async cleanupPending() {
      if (this.pendingImages.length > 0) {
        for (let img of this.pendingImages) {
          console.log("cleaning up pending image", img);
          await storage
            .refFromURL(img)
            .delete()
            .then((task) => console.log("delete", task));
        }
      }
    },
  },
  computed: {
    ...mapState(["userProfile"]),
    profileContent() {
      return this.$sanitize(this.profile);
    },
    ucid() {
      return this.userProfile.ucid;
    },
    originalImages() {
      return getImages(this.original);
    },
    newImages() {
      return getImages(this.profileContent);
    },
    isMyProfile() {
      return (this.userProfile.uid === this.author) || (!this.author && this.userProfile.ucid === this.user);
    },
  },
  created() {
    window.addEventListener("beforeunload", this.cleanupPending);
  },
  async mounted() {
    await this.loadProfile();
    this.isEditing = this.edit === "edit" && this.isMyProfile;
  },
  async beforeDestroy() {
    window.removeEventListener("beforeunload", this.cleanupPending);
    this.cleanupPending();
  },
};
</script>
